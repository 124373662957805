<template>
  <div
    v-editable="blok"
    class="SectionHeadingBlock layout-container"
    :class="[desktopWidth('full'), {
      'text-center' : blok.textAlign === 'center',
    }]"
  >
    <div v-if="blok.image && blok.image.filename" class="mb-18">
      <img
        :src="blok.image.filename"
        :alt="blok.image.alt"
        class="inline-block w-48 h-48 desk:w-64 desk:h-64"
      >
    </div>

    <div
      class="leading-single"
      :class="{

        'type-headline-xxs desk:type-headline-xs' : blok.size === 'xs',
        'type-headline-xs desk:type-headline-sm' : blok.size === 'small',
        'type-headline-sm desk:type-headline-lg' : blok.size === 'medium',
        'type-headline-lg desk:type-headline-2xl' : blok.size === 'large',
        'type-headline-2xl desk:type-headline-3xl' : blok.size === 'xl',
        'type-headline-3xl desk:type-headline-4xl' : blok.size === 'xxl',
      }"
    >
      {{ blok.heading }}
    </div>

    <div class="mt-8 empty:hidden type-sm desk:type-base" v-html="renderRichText(blok.text)" />
  </div>
</template>

<script lang="ts" setup>
import { StoryBlokAsset, StoryBlokPropBase } from '~/constants/types/storyblok';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import { ISbRichtext } from 'storyblok-js-client';

type Props = StoryBlokPropBase & {
  heading: string;
  size: 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl';
  textAlign: 'left' | 'center';
  text: ISbRichtext,
  image: StoryBlokAsset,
}

const props = defineProps<{
  blok: Props,
}>();

const { desktopWidth } = useDesktopWidth(props);
</script>
